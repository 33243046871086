export default {
  environment: 'production',
  apiKey: 'AIzaSyBUGFUA7f4JTuDDWXmdcOWl2Q8eTxDURb8',
  authDomain: 'hiber-web-prod.firebaseapp.com',
  databaseURL: 'https://hiber-web-prod.firebaseio.com',
  projectId: 'hiber-web-prod',
  storageBucket: 'hiber-web-prod.appspot.com',
  messagingSenderId: '760084251484',
  host: {
    customer: 'https://web.api.hiber.cloud',
    support: 'https://web.support-api.hiber.cloud',
  },
  mapsApiKey: 'AIzaSyBSt_k3tq7-Lkfk557yVfj7NiTjUhQW_cg',
  sentryUrl: 'https://839e94954c43ba8c94fe0a963fe2851b@o4505946915209216.ingest.sentry.io/4506065969348608',
  hiberCloudUrl: 'https://hiber.cloud',
};
